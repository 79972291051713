import React from 'react';
import * as R from 'ramda';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../components/form-footer';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// icons
import * as I from '../../../svgs';
// ui
import { Box, Flex, StickedFlex } from '../../../ui';
// feature new-do
import Tabs from '../components/tabs';
import { enhanceStopForm } from '../hocs';
import FormHeader from '../forms/form-header';
import ItemsArray from '../forms/items-array';
import { FORM_BOX_SHADOW } from '../constants';
import { Fieldset } from '../forms/formik/fieldset';
import ContactsArray from '../forms/contacts-array';
import PrevNextAction from '../forms/validate-action';
import ReferencesArray from '../forms/references-array';
import SectionDivider from '../components/section-divider';
import { getStopTitle, getStopHeaderActionOptions } from '../helpers';
import { getValidationSchemaDropFormLazy, getValidationSchemaPickupFormLazy } from '../validation';
import {
  mainStopFields,
  apptsStopFields,
  addressStopFields,
  dropItemStopFields,
  pickupInfoStopFields,
} from '../settings/fields-settings';
//////////////////////////////////////////////////

export const StopActions = ({ addNewStopToStore }: Object) => {
  const darkBlueColor = G.getTheme('colors.dark.blue');

  return (
    <Flex px={10}>
      <Box
        mx={10}
        height={15}
        cursor='pointer'
      >
        {I.plusRound(darkBlueColor)}
      </Box>
      <Box flexShrink={0}>
        <Flex
          fontSize={11}
          cursor='pointer'
          color={darkBlueColor}
          flexDirection='column'
          textDecoration='underline'
        >
          <Box onClick={() => addNewStopToStore(GC.EVENT_TYPE_PICKUP)}>
            {G.getWindowLocale('titles:add-pickup', 'Add Pickup')}
          </Box>
          <Box onClick={() => addNewStopToStore(GC.EVENT_TYPE_DROP)}>
            {G.getWindowLocale('titles:add-drop', 'Add Drop')}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

const FormTabs = ({ activeTab, tabOptions, addNewStopToStore, onClickTabCallback }: Object) => (
  <StickedFlex
    top='0px'
    zIndex={16}
    justifyContent='space-between'
    bg={G.getTheme('colors.bgGrey')}
  >
    <Tabs
      width={500}
      overflow='auto'
      options={tabOptions}
      defaultActiveName={1}
      activeFromParent={activeTab}
      onClickTabCallback={onClickTabCallback}
    />
    <StopActions addNewStopToStore={addNewStopToStore} />
  </StickedFlex>
);

const StopFormWithItems = (props: Object) => {
  const {
    stop,
    values,
    withTabs,
    setValues,
    openModal,
    activeTab,
    closeModal,
    branchGuid,
    submitForm,
    setFieldValue,
    showReferences,
    handleClickPrev,
    showPrevNextAction,
    onClickTabCallback,
    handleGetLoadOptions,
    handleChangeApptTime,
  } = props;

  const isPickup = G.isStopPickup(stop);

  return (
    <Box>
      <form>
        {
          withTabs &&
          <FormTabs {...props} />
        }
        <Box pb={G.ifElse(G.isStopDrop(stop), 20)} boxShadow={FORM_BOX_SHADOW}>
          <FormHeader
            values={values}
            setValues={setValues}
            openModal={openModal}
            closeModal={closeModal}
            setFieldValue={setFieldValue}
            options={getStopHeaderActionOptions(props)}
            currentBranch={{ [GC.FIELD_GUID]: branchGuid }}
          >
            {getStopTitle(stop, values)}
          </FormHeader>
          <Box pt={20}>
            <Fieldset
              {...props}
              fields={mainStopFields}
              getLoadOptions={handleGetLoadOptions}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:address', 'Address', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={addressStopFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:pickup-info', 'Pickup Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={pickupInfoStopFields}
            />
            <SectionDivider
              text={G.getWindowLocale('titles:appointment-info', 'Appointment Info', { caseAction: 'upperCase' })}
            />
            <Fieldset
              {...props}
              fields={apptsStopFields}
              handleChangeInput={handleChangeApptTime}
            />
            <ContactsArray contacts={R.pathOr([], [GC.FIELD_CONTACTS], values)} />
            {
              isPickup && <ItemsArray {...props} hideContainerField={true} />
            }
            {
              R.not(isPickup) &&
              <SectionDivider
                text={G.getWindowLocale('titles:drop-items', 'Drop Items', { caseAction: 'upperCase' })}
              />
            }
            {
              R.not(isPickup) &&
              <Fieldset
                {...props}
                fields={dropItemStopFields}
              />
            }
            {showReferences && <ReferencesArray {...props} />}
            {
              showPrevNextAction &&
              <PrevNextAction
                showNext={true}
                submitForm={submitForm}
                handleClickPrev={handleClickPrev}
              />
            }
          </Box>
        </Box>
      </form>
      <StickedFlex
        bottom='0'
        zIndex={11}
        bg={G.getTheme('colors.white')}
        boxShadow='0 0 8px 0 rgb(0 0 0 / 20%)'
      >
        <FormFooter
          closeModal={closeModal}
          boxStyles={{ p: '15px' }}
          submitAction={() => onClickTabCallback(activeTab, true)}
        />
      </StickedFlex>
    </Box>
  );
};

const enhance = compose(
  withFormik({
    enableReinitialize: true,
    validationSchema: (props: Object) => G.ifElse(
      G.isStopPickup(G.getPropFromObject(GC.FIELD_STOP, props)),
      getValidationSchemaPickupFormLazy(props),
      getValidationSchemaDropFormLazy(props),
    ),
    mapPropsToValues: ({ initialValues }: Object) => G.setEventInitialDroppedContainers(
      G.setEventEarlyLateTimeFromEarlyLateDate(initialValues),
    ),
    displayName: 'StopFormWithItems',
  }),
  enhanceStopForm,
  pure,
);

export default enhance(StopFormWithItems);
