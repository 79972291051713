import * as R from 'ramda';
import { FieldArray } from 'formik';
import React, { useState, Fragment } from 'react';
import { pure, compose, withHandlers } from 'react-recompose';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Fieldset2 } from '../../../forms/formik/fieldset2/fieldset';
// hocs
import withChargeComments from '../../../hocs/with-charge-comments';
// ui
import { Box, Flex, StickedBox, MainActionButton } from '../../../ui';
// feature payroll
import { ChargeInfo, ChargeAdditionInfo } from './payroll-charges-ui';
import { accessorialFieldsToOmit, initialChargeFieldsetFields } from '../settings/payroll-charges-settings';
import {
  rateTypeOptions,
  chargeInitFields,
  rateUnitOptionsGroup,
} from '../settings/payroll-with-charges-settings';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const blueColor = G.getTheme('colors.light.blue');

const chargesArrayName = GC.FIELD_PAYROLL_CHARGES;

const createCharge = () => ({
  id: G.genShortId(),
  ...chargeInitFields,
});

const createDeductionCharge = () => R.assoc(GC.FIELD_CHARGE_DEDUCTION, true, createCharge());

const Header = ({ unshift }: Object) => (
  <Flex mt={15} mb={10}>
    <MainActionButton mr={15} height={30} width={120} type='button' onClick={() => unshift(createCharge())}>
      {G.getWindowLocale('titles:add-additional', 'Add Additional')}
    </MainActionButton>
    <MainActionButton mr={15} height={30} width={120} type='button' onClick={() => unshift(createDeductionCharge())}>
      {G.getWindowLocale('titles:add-deduction', 'Add Deduction')}
    </MainActionButton>
  </Flex>
);

const commonTotalProps = {
  fontSize: 14,
  p: '2px 10px',
  bg: blueColor,
  fontWeight: 700,
  color: whiteColor,
  borderRadius: '3px',
  display: 'inline-block',
};

const Footer = ({
  currency,
  grandTotal,
  driverDeductionTotal,
  additionalPayrollPay,
  totalDriverTripCharges,
}: Object) => (
  <StickedBox p='15px 0' bottom='0px'>
    <Flex justifyContent='flex-end'>
      <TextComponent {...commonTotalProps}>
        {
          `${G.getWindowLocale('titles:driver-deduction', 'Driver Deduction')}: ${
            currency} ${G.toFixed(driverDeductionTotal)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:additional-payroll-pay', 'Additional Payroll Pay')}: ${
            currency} ${G.toFixed(additionalPayrollPay)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:total-driver-trip-charges', 'Total Driver Trip Charges')}: ${
            currency} ${G.toFixed(totalDriverTripCharges)}`
        }
      </TextComponent>
      <TextComponent {...commonTotalProps} ml={15}>
        {
          `${G.getWindowLocale('titles:gross-total', 'Gross Total')}: ${
            currency} ${G.toFixed(grandTotal)}`
        }
      </TextComponent>
    </Flex>
  </StickedBox>
);

const getOptionsFromAccessorials = R.map((item: Object) => ({
  value: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
  label: R.prop(GC.FIELD_DISPLAYED_VALUE, item),
}));

const getFields = (charge: Object, showAllFields: boolean) => {
  const { rateType } = charge;

  let fields = initialChargeFieldsetFields;

  if (G.isFalse(showAllFields)) fields = R.remove(6, 2, fields);

  if (R.or(R.equals(rateType, GC.CHARGE_RATE_TYPE_FLAT), G.isNilOrEmpty(rateType))) {
    fields = R.remove(5, 1, fields);

    return fields;
  }

  return fields;
};

const ChargeItem = (props: Object) => {
  const {
    charge,
    glDisabled,
    chargeIndex,
    glCodeOptions,
    payrollCurrency,
    handleChangeCharge,
    accessorialsConfigs,
    handleChangeChargeAccessorial,
  } = props;

  const { rateType } = charge;

  const [showAllFields, setShowAllFields] = useState(false);

  const fields = getFields(charge, showAllFields);

  return (
    <Box mt={10} mb={30} width={1700}>
      <Flex>
        <ChargeInfo {...props} showAllFields={showAllFields} setShowAllFields={setShowAllFields} />
        <Fieldset2
          {...G.getFormikProps(props)}
          {...G.getArrayFormikProps(props)}
          fields={fields}
          arrayItem={charge}
          fieldsetType='array'
          glDisabled={glDisabled}
          itemIndex={chargeIndex}
          arrayName={chargesArrayName}
          payrollCurrency={payrollCurrency}
          rateTypeOptions={rateTypeOptions}
          glCodeOptions={R.or(glCodeOptions, [])}
          currencyOptions={GC.CURRENCY_OPTIONS_2}
          handleChangeCharge={handleChangeCharge}
          additionFieldComponentProps={['payrollCurrency']}
          fieldsWrapperStyles={{ width: '100%', flexWrap: 'nowrap' }}
          handleChangeChargeAccessorial={handleChangeChargeAccessorial}
          rateUnitOptions={R.pathOr([], [rateType], rateUnitOptionsGroup)}
          accessorialsOptions={getOptionsFromAccessorials(R.or(accessorialsConfigs, []))}
        />
        <ChargeAdditionInfo {...props} />
      </Flex>
    </Box>
  );
};

const getChargeFields = (
  fieldValue: string,
  accessorials: Array,
  initCharge: Object,
  glCodeMappings: Object,
) => {
  const accessorial = R.find(R.propEq(fieldValue, GC.FIELD_ACCESSORIAL_DISPLAYED_VALUE), accessorials);
  const assessorialConfigGuid = G.getPropFromObject(GC.FIELD_ACCESSORIAL_ORIGINAL_CONFIG_GUID, accessorial);
  const glCode = R.pathOr('', [GC.INVOICE_MAPPING_TYPE_ASSESSORIALS, assessorialConfigGuid], glCodeMappings);

  return R.compose(
    R.mergeRight({ ...initCharge, glCode, assessorialConfigGuid }),
    R.omit(accessorialFieldsToOmit),
  )(accessorial);
};

const getChargeFieldName = R.compose(
  R.last(),
  R.split('.'),
);

const isRateOrQuantityChargeField = (fieldName: string) => R.or(
  R.equals(GC.FIELD_CHARGE_RATE, fieldName),
  R.equals(GC.FIELD_CHARGE_QUANTITY, fieldName),
);

const recalculateChargesOnChangeAccessorial = ({
  value,
  values,
  chargeIndex,
  glCodeMappings,
  initChargeFields,
  accessorialsConfigs,
}: Object) => R.update(
  chargeIndex,
  getChargeFields(value, accessorialsConfigs, R.mergeRight(createCharge(), initChargeFields), glCodeMappings),
  R.pathOr([], [chargesArrayName], values),
);

const recalculateChargesOnChangeRateOrQuantity = ({
  value,
  values,
  chargeIndex,
  chargeFieldName,
}: Object) => {
  const charges = R.pathOr([], [chargesArrayName], values);

  const currentChargeWithValue = R.assoc(chargeFieldName, value, R.nth(chargeIndex, charges));

  const currentChargeWithTotal = R.assoc(
    GC.FIELD_CHARGE_TOTAL,
    R.multiply(
      R.propOr(1, GC.FIELD_CHARGE_RATE, currentChargeWithValue),
      R.propOr(1, GC.FIELD_CHARGE_QUANTITY, currentChargeWithValue),
    ),
    currentChargeWithValue,
  );

  return R.update(chargeIndex, currentChargeWithTotal, charges);
};

const getChargeIndexAndCurrentCharges = (props: Object, charge: Object) => {
  const { values } = props;

  const currentCharges = R.pathOr([], [chargesArrayName], values);

  const chargeIdOrGuid = G.getIdOrGuidFromObject(charge);

  const index = R.findIndex(
    (item: Object) => R.or(
      R.propEq(chargeIdOrGuid, GC.FIELD_ID, item),
      R.propEq(chargeIdOrGuid, GC.FIELD_GUID, item),
    ),
    currentCharges,
  );

  return { index, currentCharges };
};

const enhance = compose(
  withHandlers({
    handleRemoveCharge: (props: Object) => (charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.remove(index, 1, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleChangeChargeAccessorial: (props: Object) => (value: string, charge: Object) => {
      const { values, setFieldValue, glCodeMappings, accessorialsConfigs } = props;

      const { index } = getChargeIndexAndCurrentCharges(props, charge);

      const initChargeFields = R.pick([GC.FIELD_COMMENTS, GC.FIELD_DEDUCTION], charge);

      setFieldValue(
        chargesArrayName,
        recalculateChargesOnChangeAccessorial({
          value,
          values,
          glCodeMappings,
          initChargeFields,
          chargeIndex: index,
          accessorialsConfigs,
        }),
      );
    },
    handleChangeCharge: (props: Object) => (event: Object, charge: Object) => {
      const { values, setFieldValue } = props;

      const value = G.getEventTargetValue(event);

      const chargeFieldName = getChargeFieldName(G.getEventTargetName(event));

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      if (isRateOrQuantityChargeField(chargeFieldName)) {
        return setFieldValue(
          chargesArrayName,
          recalculateChargesOnChangeRateOrQuantity({ value, values, chargeFieldName, chargeIndex: index }),
        );
      }

      const newCharge = R.assoc(chargeFieldName, value, charge);

      const updatedItems = R.update(index, newCharge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
    handleAddChargeComment: (props: Object) => (charge: Object) => {
      const { setFieldValue } = props;

      const { index, currentCharges } = getChargeIndexAndCurrentCharges(props, charge);

      const updatedItems = R.update(index, charge, currentCharges);

      setFieldValue(chargesArrayName, updatedItems);
    },
  }),
  withChargeComments(),
  pure,
);

const PayrollChargesNew = (props: Object) => {
  const {
    values,
    widthToUse,
    glDisabled,
    asyncConfigs,
    withoutTotals,
    glCodeMappings,
    handleRemoveCharge,
    handleChangeCharge,
    accessorialsConfigs,
    handleAddChargeComment,
    handleOpenChargeComment,
    handleChangeChargeAccessorial,
  } = props;

  const charges = R.pathOr([], ['values', chargesArrayName], props);

  const payrollCurrency = G.getPropFromObject(GC.FIELD_CURRENCY, values);

  const payrollInvoicesTotal = R.pathOr(0, ['values', GC.FIELD_PAYROLL_INVOICES_TOTAL], props);

  const chargesTotal = G.calculateChargesTotal(charges);

  const driverDeductionTotal = G.calculatePayrollDriverDeduction(charges);

  const additionalPayrollPay = R.subtract(chargesTotal, driverDeductionTotal);

  const grandTotal = R.subtract(R.add(payrollInvoicesTotal, additionalPayrollPay), driverDeductionTotal);

  const glCodeOptions = G.addEmptyOptionToDropDown(
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
      asyncConfigs,
      GC.INVOICE_GL_CODE,
    ),
    G.getWindowLocale('titles:gl-code', 'GL Code'),
  );

  const showPayedFor = R.any(
    ({ payedForTruck, payedForDriver }: Object) => R.or(
      G.isNotNilAndNotEmpty(payedForTruck),
      G.isNotNilAndNotEmpty(payedForDriver),
    ),
    charges,
  );

  return (
    <Box
      px={15}
      width={widthToUse}
      borderTop='1px solid'
      borderColor={G.getTheme('colors.bgLightGrey')}
    >
      <FieldArray
        name={chargesArrayName}
        render={(arrayHelpers: Object) => (
          <Fragment>
            <Header {...props} unshift={arrayHelpers.unshift} />
            <Box pt='5px'>
              {
                R.gt(R.length(charges), 0) &&
                charges.map((item: string, i: string) => (
                  <ChargeItem
                    {...arrayHelpers}
                    {...G.getFormikProps(props)}
                    key={item.id}
                    charge={item}
                    chargeIndex={i}
                    glDisabled={glDisabled}
                    showPayedFor={showPayedFor}
                    glCodeOptions={glCodeOptions}
                    glCodeMappings={glCodeMappings}
                    payrollCurrency={payrollCurrency}
                    handleRemoveCharge={handleRemoveCharge}
                    handleChangeCharge={handleChangeCharge}
                    accessorialsConfigs={accessorialsConfigs}
                    handleAddChargeComment={handleAddChargeComment}
                    handleOpenChargeComment={handleOpenChargeComment}
                    handleChangeChargeAccessorial={handleChangeChargeAccessorial}
                  />
                ))
              }
            </Box>
            {
              R.not(withoutTotals) &&
              <Footer
                grandTotal={grandTotal}
                currency={payrollCurrency}
                driverDeductionTotal={driverDeductionTotal}
                additionalPayrollPay={additionalPayrollPay}
                totalDriverTripCharges={payrollInvoicesTotal}
              />
            }
          </Fragment>
        )}
      />
    </Box>
  );
};

export default enhance(PayrollChargesNew);
