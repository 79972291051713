import React from 'react';
// components
import { TextComponent } from '../../../components/text';
// helpers/constants
import * as G from '../../../helpers';
// ui
import { AbsoluteBox } from '../../../ui';
// feature profile
import { CardUi } from '../ui';
//////////////////////////////////////////////////

const whiteColor = G.getTheme('colors.white');
const darkBlueColor = G.getTheme('colors.dark.blue');

const buttonStyles = {
  px: 15,
  height: 36,
  fontSize: 14,
  borderRadius: '4px',
  border: '1px solid',
  bgColor: whiteColor,
  textColor: darkBlueColor,
  borderColor: darkBlueColor,
};

const CardTitle = ({ title }: Object) => (
  <TextComponent
    mb={20}
    fontSize={16}
    display='block'
    color='mainDark'
    fontWeight='bold'
  >
    {title}:
  </TextComponent>
);

const Card = (props: Object) => {
  const {
    title,
    children,
    ActionComponent,
    cardUnderPageTitle = false,
  } = props;

  const borderRadius = G.ifElse(
    G.isTrue(cardUnderPageTitle),
    '0 8px 8px 8px',
    '8px',
  );

  return (
    <CardUi
      flex={1}
      borderRadius={borderRadius}
      withActions={G.isNotNilAndNotEmpty(ActionComponent)}
    >
      <CardTitle title={title} />
      {children}
      {
        ActionComponent &&
        <AbsoluteBox right={15} bottom={15}>
          <ActionComponent buttonStyles={buttonStyles} />
        </AbsoluteBox>
      }
    </CardUi >
  );
};

export default Card;

export { buttonStyles };
