import React from 'react';
// components
import { IconStatusComponent, GroupComponent } from '../components/notification-item';
// helpers/constants
import * as G from '../../../helpers';
// icons
import * as I from '../../../svgs';
//////////////////////////////////////////////////

export const columnSettings = {
  read: {
    width: 70,
    name: 'titles:read',
    customComponent: ({data, name}: Object) => (
      G.ifElse(
        G.isFalse(data.read),
        I.uiFalse(),
        I.uiTrue(),
      )
    ),
  },
  createdDate: {
    width: 150,
    name: 'titles:date-time',
  },
  warningLevel: {
    width: 100,
    name: 'titles:type',
    customComponent: ({ data, name }: Object) => (
      <IconStatusComponent warningType={name} />
    ),
  },
  displayId: {
    width: 170,
    name: 'titles:group',
    customComponent: ({ data, name }: Object) => (
      <GroupComponent
        name={name}
        type={data.objectType}
        objectGuid={data.objectGuid} />
    ),
  },
  message: {
    width: 450,
    name: 'titles:description',
  },
};

export const report = {
  fields: [
    { freezed: false, name: 'read', sequence: 1 },
    { freezed: false, name: 'createdDate', sequence: 2 },
    { freezed: false, name: 'warningLevel', sequence: 3 },
    { freezed: false, name: 'displayId', sequence: 4 },
    { freezed: false, name: 'message', sequence: 5 },
  ],
};
