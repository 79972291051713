import { borderRadius } from 'styled-system';
import styled, { css } from 'styled-components';
// ui
import { RelativeBox } from '../../ui';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

const colorWhite = G.getTheme('colors.white');

const flexLeft = css`
  display: flex;
  align-items: center;
  justify-content: left;
`;

const itemStyles = css`
  padding: 10px 20px;
`;

export const SectionWrapper = styled.section`
  background-color: ${() => colorWhite};
`;

export const SectionHeader = styled.section`
  ${flexLeft};
`;

export const SectionLeftItem = styled.div`
  ${itemStyles};

  width: 320px;
`;

export const SectionRightItem = styled.div`
  ${itemStyles};
`;

export const CardUi = styled(RelativeBox)`
  ${borderRadius};

  padding:${({ withActions }: Object) => withActions && '15px 15px 60px'};
`;

CardUi.defaultProps = {
  width: 515,
  padding: 15,
  minWidth: 325,
  minHeight: 350,
  border: '1px solid',
  background: colorWhite,
  borderColor: '#E0E0E0',
};
